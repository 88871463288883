import $ from 'jquery/dist/jquery';
window.$ = window.jQuery = $;
import Popper from '@popperjs/core/dist/umd/popper.min';
import * as bootstrap from 'bootstrap';
import PerfectScrollbar from 'perfect-scrollbar/dist/perfect-scrollbar';
import select2 from 'select2/dist/js/select2.min.js';
window.ApexCharts = require('apexcharts');
import slick from 'slick-carousel/slick/slick';
import feather from 'feather-icons/dist/feather.min.js';
import noUiSlider from 'nouislider/dist/nouislider.min.js';
import Cropper from 'cropperjs/dist/cropper.js';




window.Swal = require('sweetalert2');
require('../../../assets/js/reqon');

$(document).ready(function () {

  // min height content body section
  //=======================================================================
  var screenHeight = $(window).height();
  var div1Height = $('header').outerHeight();
  var div2Height = $('footer').outerHeight();
  var reducedHeight = screenHeight - div1Height - div2Height;
  // Set the height of div3
  $('#name').height(reducedHeight);

  // slide in modal
  // =========================================================================
  $('.slideIn-modal-btn').on('click', function () {
    var buttonDataId = $(this).data('id');
    $('body').addClass('show-overlay');

    $('.slideIn-modal').each(function () {
      var divDataId = $(this).data('id');

      if (buttonDataId === divDataId) {
        $(this).addClass('open');
      } else {
        $(this).removeClass('open');
      }
    });
  });
  $('.overlay, .close-overlay').on('click', function () {
    $('body').removeClass('show-overlay');
    $('.slideIn-modal').removeClass('open');
  })

  // open mobile filter
  $('.mobile-filterBtn').on('click', function () {
    $('body').toggleClass('mobile-filter-open');
  })
  $('.filter-section .applyBtn, .btn-dark').on('click', function () {
    $('body').removeClass('mobile-filter-open');
  })


  var config = {
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    init: function ($element) {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]', $element))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      });

      let cropper;
      let cropperModalId = '#dpChange';
      let $jsPhotoUploadInput = $('#dp-upload', $element);

      $jsPhotoUploadInput.on('change', function ($element) {
        var files = this.files;
        if (files.length > 0) {
          var photo = files[0];

          var reader = new FileReader();
          reader.onload = function (event) {
            $('.error-message').text("");
            var image = document.getElementById('previewDP');
            image.src = event.target.result;

            $(cropperModalId).modal('show');

            if (cropper) {
              cropper.replace(event.target.result);
            } else {
              $('.js-save-cropped-avatar', $element).on('click', function (event) {
                event.preventDefault();

                var $button = $(this);
                // $button.text('Uploading...');
                $button.prop('disabled', true);


                const canvas = cropper.getCroppedCanvas();
                const base64encodedImage = canvas.toDataURL();
                $('#uploaded-decode').val(base64encodedImage);
                $('#profile-img').attr('src', base64encodedImage);
                $(cropperModalId).modal('hide');

                // $button.text('Save');
                $button.prop('disabled', false);
              });
              $(cropperModalId, $element).on('hidden.bs.modal', function (e) {
                var reader = new FileReader();
                $('.js-save-cropped-avatar').prop('disabled', false);
              });
              cropper = new Cropper(image, {
                viewMode: 1,
                aspectRatio: 1,
                minContainerWidth: 300,
                minContainerHeight: 300,
                minCropBoxWidth: 50,
                minCropBoxHeight: 50,
                movable: true,
                ready: function () {
                  console.log('ready');
                }
              });
            }
            image.onerror = function () {
              $('.error-message').text("Error reading file");
              console.error('Error loading image');
            };
          };
          reader.readAsDataURL(photo);
        }
      });

      $('.js-save-cropped-avatar', $element).on('click', function (event) {
        event.preventDefault();

        var $button = $(this);
        // $button.text('Uploading...');
        $button.prop('disabled', true);


        const canvas = cropper.getCroppedCanvas();
        const base64encodedImage = canvas.toDataURL();
        $('#uploaded-decode').val(base64encodedImage);
        $('#profile-img').attr('src', base64encodedImage);
        $(cropperModalId).modal('hide');

        // $button.text('Save');
        $button.prop('disabled', false);
      });
      $(cropperModalId, $element).on('hidden.bs.modal', function (e) {
        var reader = new FileReader();
        $('.js-save-cropped-avatar').prop('disabled', false);
      });



      var charLimit = 1;
      $(".otp-field", $element).keydown(function (e) {
        var keys = [8, 9, /*16, 17, 18,*/ 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 144, 145];

        if (e.which == 8 && this.value.length == 0) {
          $(this).prev('.otp-field').focus();
        } else if ($.inArray(e.which, keys) >= 0) {
          return true;
        } else if (this.value.length >= charLimit) {
          $(this).next('.otp-field').focus();
          return false;
        } else if (!((e.which >= 48 && e.which <= 57) || (e.which >= 65 && e.which <= 90) || (e.which >= 97 && e.which <= 122))) {
          return false;
        }
      }).keyup(function () {
        if (this.value.length >= charLimit) {
          $(this).next('.otp-field').focus();
          return false;
        }
      });


      $('.ad-slider',$element).slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnFocus: false
      });

      $('.ad-slider-filter',$element).slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 1000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnFocus: false
      });

      feather.replace();


      // Add to fav btn
      // ==================================================
      $('.fav',$element).on('click', function() {
          $(this).toggleClass('added');
      });




      $(".custom-file-input", $element).change(function (t) {
        if (t.target.files && t.target.files[0]) {
          var e = new FileReader,
            i = $(this).closest(".custom-file");

          i.find(".custom-file-label").html(t.target.files[0].name);
          i.find(".default-image").hide();
          i.find(".custom-file-label").html("");
          e.onload = function (t) {
            var previewImage = i.find(".custom-file-preview");
            var fileType = t.target.result.split(";")[0].split(":")[1];

            if (fileType.startsWith('video')) {
              var imagePath = previewImage.attr("video-img");
              previewImage.attr("src", imagePath);
            } else {
              if ($('.custom-file-preview-video').hasClass('custom-file-preview-video')) {
                $('.custom-file-preview-video').remove();
              }
              previewImage.attr("src", t.target.result);
            }


            // Show the remove image text and handle the removal
            previewImage.after('<div class="remove-image-text">Remove</div>');
            $(".remove-image-text").on("click", function () {
              // Reset the file input and hide the preview
              $(".custom-file-input", $element).val("");

              previewImage.attr("src", "").hide();

              // Remove the "Remove Image" text
              $(this).remove();

              i.find(".default-image").show();
              i.find(".custom-file-label").html("Choose file");
            });

            previewImage.hide().fadeIn(650);
          };

          e.readAsDataURL(t.target.files[0]);
        }


      });

      if ($(".remove-image-text").is(":visible")) {
        $(".custom-file").find(".custom-file-label").hide()
      } else {
        $(".custom-file").find(".custom-file-label").show()
      }

      $('.select2', $element).each(function () {
        let form = $(this).closest('form');
        $(this).select2({ dropdownParent: form.length ? form : $('body') });
      });

      $('.select2-tags', $element).each(function () {
        let form = $(this).closest('form');

        $(this).select2({ dropdownParent: form.length ? form : $('body'), tags: true });
      });



      $(document).ready(function() {
        $('.js-example-basic-multiple').each(function () {
            let form = $(this).closest('form');
            $(this).select2({
                dropdownParent: form.length ? form : $('body'),
                closeOnSelect: false
            }).on('select2:select', function (e) {
                // Clear only the typed text in the search field
                $(this).next('.select2-container').find('.select2-search__field').val('');
            }).on('select2:unselecting', function (e) {
                $(this).select2('close');
            });
        });
    });
    
    



      $(".custom-file .remove-image-text").on("click", function ($element) {
        // Reset the file input and hide the preview
        $(".custom-file-input", $element).val("");
        $(this).closest(".custom-file").find(".custom-file-preview").attr("src", "").hide();
        $(this).closest(".custom-file").find(".custom-file-preview-video").attr("src", "").hide();
        // Remove the "Remove Image" text
        $(".remove-image-text").hide();
        $("#removeImageInput").val(1);

        $(".custom-file").find(".custom-file-label").show()
        $(this).closest(".custom-file").find(".default-image").show();
        $(this).closest(".custom-file").find(".custom-file-label").html("Choose file");
      });




      initializeAutocompleteWithMap($element);


      function initializeAutocompleteWithMap($element) {
        $('.location-map', $element).each(function (index, el) {
          var input = $(el);


          const defaultLocation = {
            lat: -34.397,
            lng: 150.644
          };
          let map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: defaultLocation
          });

          // Hide map initially
          $('#map').hide();

          const circle = new google.maps.Circle({
            center: defaultLocation,
            radius: 10000 // 10 km
          });

          let autocomplete = new google.maps.places.Autocomplete(input[0], {
           // types: ['(cities)'],
            componentRestrictions: { country: 'IN' },
            bounds: circle.getBounds()
          }
          );


          // Bind the map's bounds to the autocomplete widget
          autocomplete.bindTo('bounds', map);



          autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();

            if (!place.geometry) {
              // Clear latitude and longitude values if place geometry is not available
              $('#location_latitude').val('');
              $('#location_longitude').val('');
              return;
            }

            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();

            $('#location_latitude').val(latitude);
            $('#location_longitude').val(longitude);

            // Get state and district from the place address components
            var addressComponents = place.address_components;
            var state = '';
            var district = '';

            addressComponents.forEach(function (component) {
              if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
              } else if (component.types.includes('administrative_area_level_2')) {
                district = component.long_name;
              } else if (component.types.includes('locality')) {
                district = component.long_name;
              }
            });

            $('#location_state').val(state);
            $('#location_district').val(district);

            var description = place.name;
            $('#location_info').val(place.formatted_address);


            mapMarkers.forEach(marker => marker.setMap(null));
            mapMarkers = [];

            // If the place has a geometry, present it on a map.
            if (place.geometry.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setCenter(place.geometry.location);
              map.setZoom(17);
            }

            // Place a marker at the selected location
            mapMarkers.push(new google.maps.Marker({
              map: map,
              position: place.geometry.location
            }));

            $('#map').show();
            //}


          });

          let mapMarkers = [];
          input.on('input', function () {
            // Your code to handle text field changes
            var currentText = input.val();
            if (currentText === '') {
              $('#location_latitude').val('');
              $('#location_longitude').val('');
              $('#location_info').val('');
              $('#location_state').val('');
              $('#location_district').val('');
              $('#map').hide();
            }
          });


        });


      }




      initializeAutocomplete($element);


      function initializeAutocomplete($element) {
        $('.location', $element).each(function (index, el) {
          var input = $(el);

          var autocomplete = new google.maps.places.Autocomplete(input[0], {
            // types: ['(cities)'],
            componentRestrictions: { country: 'IN' },
            //bounds: circle.getBounds()

          });


          autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();

            if (!place.geometry) {
              // Clear latitude and longitude values if place geometry is not available
              $('#location_latitude').val('');
              $('#location_longitude').val('');
              return;
            }

            var latitude = place.geometry.location.lat();
            var longitude = place.geometry.location.lng();

            $('#location_latitude').val(latitude);
            $('#location_longitude').val(longitude);

            var description = place.name;
            $('#location_info').val(place.formatted_address);

          });

          let mapMarkers = [];
          input.on('input', function () {
            // Your code to handle text field changes
            var currentText = input.val();
            if (currentText === '') {
              $('#location_latitude').val('');
              $('#location_longitude').val('');
              $('#location_info').val('');
            }
          });


        });


      }

      $('form').on('reset', function () {
        // Reset form fields
        $(this)[0].reset();
        // Reset Select2 fields
        setTimeout(function () {
          $('select').trigger('change');
        }, 100); // Adjust the delay time as needed
      });


    },
    processing: function ($element, status) {
      $element.find('button[type="submit"]').prop('disabled', status);
    },
    confirm: async function (message) {


      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: message,
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: () => !Swal.isLoading(), // Allow outside click only if not loading
          confirmButtonColor: '#757575',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b40606',
          confirmButtonText: 'Yes, Proceed',
        });

        // Check if result.isConfirmed is undefined (click outside)
        if (typeof result.isConfirmed === 'undefined') {
          return false; // Cancel action
        }

        return result.isConfirmed; // Return true for confirmation, false for cancel
      } catch (error) {
        console.error('Error in confirm dialog:', error);
        return false;
      }

    },
    respond: function ($element, json) {  
      if (json.resetTimer) {
       // console.log(json.resetTimer.functionName)
        resetTimer()
      }

      if (json.errors) {
        $('.is-invalid').removeClass('is-invalid');
        $('.invalid-feedback').remove();

        // $.each(json.errors, (name, errors) => {
        //     const field = name.includes('.') ? name.split('.').join('][') : name;
        //     const $errorElement = $(`[name='${field}']`);
        //     if ($errorElement.length) {
        //         const feedback = $('<div>').addClass('invalid-feedback').html(errors[0]);
        //         $errorElement.addClass('is-invalid').closest('div').append(feedback);
        //         $errorElement.one('change', () => {
        //             $errorElement.removeClass('is-invalid').closest('div').find('.invalid-feedback').remove();
        //         });
        //     }
        // });
        $.each(json.errors, (name, errors) => {
          const field = name.includes('.') ? name.split('.').join('][') : name;
          const $errorElements = $(`[name^='${field}']`); // Use ^= to select elements with names starting with 'field'
          if ($errorElements.length) {
            $errorElements.each((index, element) => {
              const $errorElement = $(element);
              const feedback = $('<div>').addClass('invalid-feedback').html(errors[0]);
              $errorElement.addClass('is-invalid').closest('div').append(feedback);
              $errorElement.one('change', () => {
                $errorElement.removeClass('is-invalid').closest('div').find('.invalid-feedback').remove();
              });
            });
          }
        });

        const firstError = $('.invalid-feedback').first();
        if (firstError.length) {
          $('html, body').animate({ scrollTop: firstError.offset().top - 80 }, 'smooth');
        }
      }

      if (json.modal) {
        if (json.modal.hide) {
          $(json.modal.hide).modal('hide');
        }
        if (json.modal.show) {
          $(json.modal.show).modal('show');
        }
      }

      if (json.alert) {
      
        let swalconfig = {
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
        }

        Object.assign(swalconfig, json.alert)

        Swal.fire(swalconfig).then(function (result) {
          if (json.alert.redirect) {
            window.location.href = json.alert.redirect;
          }
        });
      }

      if (json.reset) {
        const $form = $element.closest('form');
        if ($form.length > 0) {
          $form[0].reset(); $('select').trigger('change');
        }
      }

    }
  }


  // menu link focus
  // ***************************************
  var $sidebar = $('.menu-inner'),
    $targetLink = $('.menu-item.active');

  // Make sure elements exist
  if ($sidebar.length && $targetLink.length) {
    var scrollToPos = $targetLink.offset().top - $sidebar.offset().top + $sidebar.scrollTop();

    $sidebar.animate({
      scrollTop: scrollToPos
    }, 500); // Adjust the duration (500ms) as needed
  } else {
    //console.log("Elements not found");
  }
  // ***************************************

 
  // 08/05/2024
  // bug fixes by AL

  $('.toggle-password').click(function() { 
      var $input = $(this).siblings('input'); 
      if ($input.attr('type') === 'password') {
          $input.attr('type', 'text');
      } else {
          $input.attr('type', 'password');
      }
      $(this).toggleClass('active');
  });

  // select2 on resize
  $(window).resize(function() {
    // $('.js-example-basic-multiple').select2(); 
    $('.js-example-basic-multiple').select2({
      closeOnSelect: false
    });
    $(".js-example-basic-single").select2();  
  });



  $('html').reqon(config);


});